<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { computed, ComputedRef, PropType } from "vue";
import { Module } from "../model/module.ts";
import moduleStore from "../store/moduleStore";

const store = moduleStore();
const props = defineProps({
  modules: {
    type: Array as PropType<Module[]>,
    required: true,
  },
  selectedCourse: {
    type: String as PropType<string>,
    required: true,
  },
});

const modules: ComputedRef<Module[]> = computed(() => props.modules);
const selectedCourse: ComputedRef<string> = computed(
  () => props.selectedCourse,
);

store.modules.clear();

const allSelected: ComputedRef<boolean> = computed(() =>
  props.modules.every((module) => store.hasModule(module)),
);

function toggleAllModules() {
  if (allSelected.value) {
    store.removeAllModules();
  } else {
    store.overwriteModules(props.modules);
  }
}

function toggleModule(module: Module) {
  if (store.hasModule(module)) {
    store.removeModule(module);
  } else {
    store.addModule(module);
  }
}
</script>

<template>
  <div class="flex flex-column w-full">
    <DataView
      :value="modules"
      data-key="uuid"
      :class="[
        selectedCourse === ''
          ? ['opacity-0', 'pointer-events-none']
          : [
              'opacity-100',
              'transition-all',
              'transition-duration-500',
              'transition-ease-in-out',
            ],
        'w-full',
      ]"
    >
      <template #header>
        <div
          class="flex justify-content-between align-items-center flex-wrap md:mx-4"
        >
          <h3>
            {{ $t("moduleSelection.modules") }} -
            {{ store.countModules() }}
          </h3>
          <div
            class="flex align-items-center justify-content-end white-space-nowrap"
          >
            <p>
              {{
                allSelected
                  ? $t("moduleSelection.deselectAll")
                  : $t("moduleSelection.selectAll")
              }}
            </p>
            <InputSwitch
              class="mx-2"
              :disabled="modules.length === 0"
              :model-value="allSelected"
              @update:model-value="toggleAllModules()"
            />
          </div>
        </div>
      </template>
      <template #empty>
        <p class="p-4 text-2xl font-bold text-900 empty-message">
          {{ $t("moduleSelection.noModulesAvailable") }}
        </p>
      </template>
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div
            v-for="(item, index) in slotProps.items"
            :key="index"
            class="col-12"
          >
            <div
              class="flex flex-column sm:flex-row justify-content-between align-items-center flex-1 column-gap-4 mx-2 md:mx-4"
            >
              <p class="text-lg flex-1 align-self-stretch">{{ item.name }}</p>
              <Button
                class="w-9rem align-self-end my-2"
                :icon="store.hasModule(item) ? 'pi pi-times' : 'pi pi-plus'"
                :label="
                  store.hasModule(item)
                    ? $t('moduleSelection.selected')
                    : $t('moduleSelection.unselected')
                "
                outlined
                :severity="store.hasModule(item) ? '' : 'secondary'"
                @click="toggleModule(item)"
              />
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<style scoped>
@media screen and (min-width: 962px) {
  .empty-message {
    width: 50rem;
  }
}
</style>
